
          @import "@/assets/css/vars.scss";
        




























































.single-table-row {
  padding: 20px 40px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  position: relative;
  &>span {
    display: inline-block;
    width: calc(100% / 8);
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #394050;
  }
  .bold {
    font-weight: bold;
  }
  .adress {
    min-width: 300px;
  }
  .zip-code {
    font-style: italic;
  }
  .present {
    text-align: center;
    display: inline-block;
    width: 100px;
  }
}
.is-content {
  &:hover {
    background: #fbece2;
    &::after {
      content: "";
      height: 100%;
      width: 4px;
      background: #f96600;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
