
          @import "@/assets/css/vars.scss";
        













































































.login-modal {
  padding: 50px 70px;
  width: 570px;
  height: 488px;
  background: #ffffff;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .input-wrap {
    padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 429px;
    height: 65px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 30px;
    .icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      margin-right: 15px;
    }
    input {
      border: none;
      width: 100%;
      height: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: $inputText;
      &:focus {
        outline: none;
      }
    }
  }
  .error-msg {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(221, 41, 41);
    font-size: 15px;
  }
  p {
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $text-gray;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    color: $light-black;
    margin-bottom: 15px;
  }
  button {
    width: 429px;
    height: 65px;
    background: $orange;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
  }
}
