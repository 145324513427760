
          @import "@/assets/css/vars.scss";
        



























































































.home {
  position: relative;
  .absolute-wrapper {
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .wrapper {
    max-width: 1320px;
    margin: 0 auto;
    padding: 120px 120px 120px 120px;
    .logo {
      display: block;
      width: 88px;
      height: 88px;
      margin:  0 0 45px calc(100% - 88px);
    }
    .warranty-wrap {
      margin: 0 0 30px 0;
    }
    .logo-title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: Xiaomi-Bold;
        font-size: 60px;
        line-height: 73px;
        letter-spacing: -0.01em;
        width: 50%;
        color: #22242c;
      }
    }
    & > p {
      font-family: Xiaomi-Regular;
      font-size: 32px;
      line-height: 27px;
      color: #4c4e52;
      margin: 40px 0 100px 0;
    }
    .flex-wrap {
      height: 700px;
      display: flex;
      justify-content: space-between;
      img {
        display: inline-block;
        width: 100%;
      }
      .phone-model-data {
        border-left: 4px solid #e7e7e7;
        padding: 0 9px;
        h2 {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #212121;
        }
        p {
          font-family: Xiaomi-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 31px;
          color: #ff6900;
        }
      }
      .flex-wrap-left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
        .phone-model-data {
          align-self: center;
          transform: translateX(-75%);
          margin-bottom: 35px;
        }
      }
      .flex-wrap-right {
        // align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          max-width: 580px;
        }
      }
    }
    .present {
      position: relative;
      margin: 30px 0 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: inline-block;
        width: 583px;
        max-width: 70%;
        margin: 0 auto;
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin: 0 auto;
      button {
        width: 201px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: Xiaomi-Regular;
        border: none;
        outline: none;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
      }
      .choose-gift {
        background: #ff6900;
        color: #ffffff;
      }
      .rules {
        background: #fff;
        color: #ff6900;
      }
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .home {
    .wrapper {
      .logo {
        margin: 0 auto;
      }
      .warranty-wrap {
        text-align: center;
        margin: 0 0 50px 0;
      }
      .logo-title-wrap {
        flex-direction: column-reverse;
        h1 {
          text-align: center;
          width: 100%;
          margin-top: 40px;
        }
      }
      & > p {
        text-align: center;
      }
      .flex-wrap {
        flex-direction: column;
        height: unset;
        img {
          max-width: 400px;
        }
        .flex-wrap-left {
          width: unset;
          align-self: center;
          justify-content: center;
          margin-bottom: 75px;
          .phone-model-data {
            transform: none;
          }
        }
        .flex-wrap-right {
          width: unset;
          align-self: center;
          margin-bottom: 75px;
        }
      }
      .button-container {
        margin: 100px 0 0 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .wrapper {
      padding: 40px;
      .logo-title-wrap {
        h1 {
          font-size: 30px;
          line-height: 40px;
        }
      }
      & > p {
        font-size: 20px;
        margin: 40px 0 100px 0;
      }
    }
    .button-container {
      button {
        font-size: 15px !important;
      }
    }
  }
}
